.markdown-body table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .markdown-body table th,
  .markdown-body table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .markdown-body table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .markdown-body table tr:nth-child(even) {
    background-color: #f9f9f9;
  }